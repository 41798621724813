$(function () {
    loginModalOnPageLoad();
    resetPasswordModalOnPageLoad();
    updatePhoneOnPageLoad();

    $('#login-modal .do-login-btn').click(doLogin);
    $('#login-modal .do-register-btn').click(doRegister);
    $('#login-modal .do-reset-btn').click(doReset);
    $('#login-modal .do-phone-btn').click(doUpdatePhone);
    $('#login-modal .modal-close').click(closeForm);

    $('#login-modal .do-return-login-btn').on('click', function() {
        showLoginForm('signin')
    })

    $('#login-modal .do-save-email').on('click', function(e) {
        doSaveEmail(e, $('#login_email_save').val(), $('#login-modal #mail_list').val());
    });

    $('#login-popup-form input').keydown(function (event) {
        if (event.keyCode == 13) {
            var type = $('#login-popup-form').data('type');
            switch (type) {
                case 'signin':
                    doLogin(false);
                    break;
                case 'signup':
                    doSaveEmail(event, $('#login_email_save').val(), $('#login-modal #mail_list').val());
                    break;
                case 'signup-full':
                    doRegister(false);
                    break;
            }
        }
    });

    $('#login_email_save').on('keyup', function() {
        validateEmail($('#login_email_save').val(), $('.loginPopup__register-error'), $('.save-email-element'));
    });

    $('#login-modal form.login-modal').submit(function (e) {
        e.preventDefault();
    });

    $('#login-modal form.forgot').submit(function (e) {
        e.preventDefault();
    });

    $('#login-modal form.phone').submit(function (e) {
        e.preventDefault();
    });

    $('#login-modal .do-forgot-password').click(function (event) {
        showForgotPassword();
    });

    $('#login-modal .modal-footer .do-recovery-btn').hide();
    $('#login-modal .modal-footer .do-reset-btn').hide();
    $('#login-modal .modal-footer .do-phone-btn').hide();
    $('#login-modal .modal-footer .do-return-login-btn').hide();

    $('#login-modal .do-recovery-btn').click(function (event) {
        event.preventDefault();
        $('#login-modal .error').remove();
        $('#submit-loader-wrap').fadeIn();
        $.ajax('/password/modal/forgot', {
            type: 'post',
            headers: {'X-CSRF-TOKEN': getCsrfToken()},
            data: $('#login-modal form.forgot').serialize(),
            success: function (response) {
                if (response.error === false) {
                    $('#login-modal .modal-footer .do-recovery-btn').hide();
                    $('#login-modal .modal-body form.forgot').html('<div class="row"><div class="col-lg-12"><p>Password Reset Link Sent. Please check your email.</p></div></div>');
                } else {
                    $('#reset-message').remove();
                    $('#login-modal .modal-body').prepend($('<div id="reset-message" class="alert text-left alert-danger">' + response.message + '</div>'));
                }
                $('#submit-loader-wrap').fadeOut();
            }
        })
    });
});

function doSaveEmail(event, email) {
    event && event.preventDefault();
    $('#submit-loader-wrap').fadeIn();

    const data = {
        email
    }
    //Step 1: Is this email already in the db?
    Utils.Ajax.getAjax('/user/email-check', data, function (response) {
        if (response.hasAccount) {
            // Show the login section w/ email prefilled
            $('#login_email_signin').val(response.email)
            switchType('signin')

        } else {
            // Back end will save the contact
            // Then we save the email in the datalayer
            // Then show the rest of the registration screen w/ email filled in
            window.dataLayer.push({
                'event': 'work_email'
            })
            $('#login_email_signin').val(response.email)
            switchType('signup-full')
        }

        $('#submit-loader-wrap').fadeOut();

    }, function (response) {
        //If the check fails, let's just go ahead and have them register
        $('#login_email_signin').val(response.email)
        switchType('signup-full')
        $('#submit-loader-wrap').fadeOut();
    });
}

function doRegister(event) {
    event && event.preventDefault();
    var asset_classes = '';

    if ($('#register_asset_classes').val() &&
        $('#register_asset_classes').val().length > 0) {
        asset_classes = $('#register_asset_classes').val().toString();
    }

    var formData = {
        _token: getCsrfToken(),
        email: $('#login_email_signin').val(),
        password: $('#login_password').val(),
        first_name: $('#register_first_name').val(),
        last_name: $('#register_last_name').val(),
        title: $('#register_title').val(),
        company: $('#register_company').val(),
        phone: $('#register_phone').val(),
        zip: $('#register_zip').val(),
        industry: $('#register_industry').val(),
        job_function: $('#register_job_function').val(),
        asset_classes: asset_classes,
        return_to_url: $('#return_to_url').val(),
        agree_to_terms: $('input[name="agree_to_terms"]:checked').val(),
        mailing_list: $('input[name="subscription_checkbox"]:checked').val()
    };

    clearErrorMessage();

    $('#submit-loader-wrap').fadeIn();

    $('body').trigger($.Event('login_modal_registration_attempt'));
    $.post('/register', formData).done(function (response) {
        $('body').trigger($.Event('login_modal_registration_success'));
        clearErrorMessage();

        if (response.returnToUrl) {
            if ($('#subscription_checkbox').prop('checked')) {
                window.dataLayer.push({
                    'event': 'subscription_checkbox',
                })
            }

            window.location.href = response.returnToUrl;
        } else {
            window.location.href = '/subscribe?email=' + $('input.login_email_signin').val() + '&remember_origin=' + encodeURIComponent(window.location.href);
        }
    }).fail(function (response) {
        $('body').trigger($.Event('login_modal_registration_error'));

        var data = response.responseJSON;

        clearErrorMessage();
        if (Array.isArray(data.errors.email) && data.errors.email[0].includes('exists')) {
            loginModalShow();
            return;
        }

        if (response.status === 400) {
            let messages = [];
            Object.entries(data.errors).forEach(function ([field, errors]) {
                $('.input_'+field).next('.error').addClass('has-invalid');
                errors.forEach(function (error) {
                    messages.push(error);
                });
            });
            showErrorMessage(messages);
        } else {
            showErrorMessage(data.message ?? 'An unexpected error occurred.');
        }
    }).always(function () {
        $('#submit-loader-wrap').fadeOut();
    });
}

function doLogin(event) {
    event && event.preventDefault() && event.stopImmediatePropagation();
    clearErrorMessage();
    $('#submit-loader-wrap').fadeIn();

    $('body').trigger($.Event('login_modal_login_attempt'));

    $.ajax('/handleLogin', {
        type: 'post',
        headers: {'X-CSRF-TOKEN': getCsrfToken()},
        data: $('#login-modal form.login-modal').serialize(),
        success: function (response) {
            if (response.status == true) {
                clearErrorMessage();
                $('#login-modal').modal('hide');
                $('body').trigger($.Event('login_modal_login_success'));

                window.dataLayer.push({
                    'login_status': 'Logged In'
                });
                // Reload the page ?

                if (response.returnToUrl) {
                    // Set the login status if call was asynchronous.
                    window.dataLayer.push({
                        'login_status': 'Logged In'
                    });
                    window.location.href = response.returnToUrl;
                } else {
                    window.location.reload();
                }
            } else {
                // Error logging in
                $('body').trigger($.Event('login_modal_login_error'));
                showErrorMessage(response.message);
                $('#submit-loader-wrap').fadeOut();
            }
        },
        error: function (jqXhr, status, error) {
            $('body').trigger($.Event('login_modal_login_error'));
        }
    });
}

/**
 * Process a password reset
 * @param event
 */
function doReset(event) {
    event && event.preventDefault();
    clearErrorMessage();
    $.ajax('/password/modal/reset', {
        type: 'post',
        headers: {'X-CSRF-TOKEN': getCsrfToken()},
        data: $('#login-modal form.reset').serialize(),
        success: function (response) {
            if (response.error === 1) {
                $('#login-modal .modal-body .error').remove();
                $('#login-modal .modal-body').prepend($('<div id="reset-message" class="error">' + response.message + '</div>'));
                $('#submit-loader-wrap').fadeOut();
            } else {
                clearErrorMessage();
                if (response.returnToUrl) {
                    $('#login-modal .modal-body').prepend($('<div id="reset-message" class="error">' + response.message + '</div>'));
                    window.location = response.returnToUrl
                } else {
                    $('#login-modal .do-reset-btn').hide();
                    $('#login-modal .modal-body form.forgot').html('<p>Password reset successfully!</p>');
                    $('#submit-loader-wrap').fadeOut();
                }
            }
        }
    })
}

function doUpdatePhone(event) {
    event && event.preventDefault();
    clearErrorMessage();
    $('#submit-loader-wrap').fadeIn();
    $.ajax('/register/update-phone', {
        type: 'patch',
        headers: {'X-CSRF-TOKEN': getCsrfToken()},
        data: $('#login-modal form.phone').serialize(),
        success: function (response) {
            if (response.error === 1) {
                showErrorMessage(response.message);
                if (response.invalid && response.invalid.length > 0) {
                    response.invalid.forEach(function (invalid) {
                        $('.input_' + invalid).addClass('has-invalid');
                    });
                }
                $('#submit-loader-wrap').fadeOut();
            } else {
                clearErrorMessage();
                if (response.returnToUrl) {
                    $('#login-modal .modal-body').prepend($('<div class="error">' + response.message + '</div>'));
                    window.location = response.returnToUrl
                } else {
                    $('#login-modal .do-phone-btn').hide();
                    $('#login-modal .modal-body form.forgot').html('<p>User updated successfully!</p>');
                    $('#submit-loader-wrap').fadeOut();
                }
            }
        }
    })
}

function getTitleFromRole(role) {
    if (role == 'signin') {
        return {
            title: 'Login to your Account',
            subTitle: 'Sign In'
        }
    } else if (role === 'signup') {
        return {
            title: 'Create an Account',
            subTitle: 'Enter Your Work Email',
        }
    } else {
        return {
            title: 'Create An Account',
            subTitle: 'Finish Creating Your Account',
        }
    }
}

function setupFormBasedOnType(type) {
    var signin = $('#signin');

    switch(type) {
        case 'signin':
            $('#loginPopup__main-form').addClass('login');
            $('#loginPopup__main-form').removeClass('register');
            $('#loginPopup__main-form').removeClass('register-full');
            $('input[name=password]').attr('autocomplete', 'off');
            $('#login-popup-form').data('type', 'signin');
            signin.prop('checked', true);
            $('#signup').prop('checked', false);
            break;
        case 'signup':
            $('#loginPopup__main-form').removeClass('login');
            $('#loginPopup__main-form').addClass('register');
            $('#loginPopup__main-form').removeClass('register-full');
            $('#login-popup-form').data('type', 'signup');
            signin.prop('checked', false);
            $('#signup').prop('checked', true);
            break;
        case 'signup-full':
            $('#loginPopup__main-form').removeClass('login');
            $('#loginPopup__main-form').removeClass('register');
            $('#loginPopup__main-form').addClass('register-full');
            $('#login-popup-form').data('type', 'signup');
            $('input[name=password]').attr('autocomplete', 'new-password');
            signin.prop('checked', false);
            $('#signup').prop('checked', true);
            break;

    }

    clearErrorMessage();
}

function switchType(type) {
    setupFormBasedOnType(type);
    const title = getTitleFromRole(type).title
    const subTitle = getTitleFromRole(type).subTitle
    $('#login-modal .modal-title').text(title)
    $('#login-modal .login-type-title').text(subTitle)
}

function showErrorMessage(messages) {
    var loginMessage = $('#login-modal .message');
    if (Array.isArray(messages)) {
        var errorList = '';

        messages.forEach(function (error) {
            errorList += '<p>' + error + '</p>';
        });

        loginMessage.html(errorList).removeClass('hidden').addClass('alert-danger').show();
    } else {
        loginMessage.html(messages).removeClass('hidden').addClass('alert-danger').show();
    }

}

function clearErrorMessage() {
    $('#login-modal .message').text('').addClass('hidden').removeClass('alert-danger').hide();
    $('.has-invalid').removeClass('has-invalid');
}

window.loginModalSubtitle = function (customTitle) {
    if (customTitle && customTitle.length) {
        jQuery('#login-modal p.modal-title').text(customTitle).show();
    }
}

window.loginModalShow = function(disallowClose, customTitle, showRegistrationType) {
    $('input[name=login-type]').change(function () {
        var type = $(this).val();
        if (type == 'signup' && $('#login_email_signin').val() ) {
            type = 'signup-full'
        }
        switchType(type);

        showLoginForm(type);
    });

    $('#register_asset_classes').select2({
        placeholder: "Select Asset Classes",
        multiple: true,
        allowClear: true
    });

    var options = {show: true};
    if (disallowClose) {
        options.keyboard = false;
        options.backdrop = 'static';
        $('#login-modal .modal-close').hide();
    } else {
        $('#login-modal .modal-close').show();
    }

    var type = 'signin';
    if (showRegistrationType == 'signup') {
        type = 'signup';
    }

    switchType(type);

    loginModalTitle(customTitle);

    var modalLogin = $('#login-modal');
    modalLogin.modal(options);

    modalLogin.on('show.bs.modal', function () {
        $('body').trigger($.Event('login_modal_shown'));
    });

    modalLogin.on('hide.bs.modal', function () {
        $('body').trigger($.Event('login_modal_hidden'));
    });

    modalLogin.modal(options);
}

function loginModalOnPageLoad() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('modal') === 'login') {
        loginModalShow();
    }
}

function updatePhoneOnPageLoad() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('modal') === 'phone') {
        updatePhoneFormShow();
        loginModalShow();
    }
}

function resetPasswordModalOnPageLoad() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('email') && urlParams.get('token')) {
        resetPasswordFormShow();
    }
}

function loginModalTitle(customTitle) {
    if (customTitle && customTitle.length) {
        $('#login-modal h4.modal-title').text(customTitle).html(customTitle);
    }
}

window.loginModalTitle = loginModalTitle

function resetPasswordFormShow() {
    $('#login-modal .modal-body form.login-modal').fadeOut('fast', function () {
        $('#login-modal .modal-title').text('RESET PASSWORD');
        $('#login-modal .modal-body form.reset').fadeIn();
        $('#login-modal .modal-body .default-view').hide();
        $('#login-modal .modal-body .social_signin').hide();
        $('#login-modal .modal-footer .do-reset-btn').show();
        $('#login-modal .modal-footer .do-login-btn').hide();
        $('#login-modal .modal-footer .do-recovery-btn').hide();
        $('#login-modal .modal-footer .do-return-login-btn').show();
        $('#login-modal .do-register-btn').hide();
        $('#login-modal .modal-footer .do-phone-btn').hide();
    });
}

function showForgotPassword() {
    $('#login-modal .modal-body form.login-modal').fadeOut('fast', function () {
        $('#loginPopup__main-form').removeClass('login');
        $('.loginPopup__main-form-header').hide()
        $('#login-modal .modal-title').text('RECOVER PASSWORD');
        $('#login-modal .modal-body form.forgot').fadeIn();
        $('#login-modal .modal-body .social_signin').hide();
        $('#login-modal .modal-footer .do-recovery-btn').show();
        $('#login-modal .modal-footer .do-login-btn').hide();
        $('#login-modal .modal-footer .do-reset-btn').hide();
        $('#login-modal .modal-footer .do-phone-btn').hide();
        $('#login-modal .do-return-login-btn').show();
        $('#login-modal .do-register-btn').hide();
    });
}

function showLoginForm(type) {

    const typeClass = type == 'signin' ? 'login' : type == 'signup' ? 'register' : type == 'signup-full' ? 'register-full' : ''

    $('#login-modal .modal-body form.forgot').fadeOut('fast', function () {
        $('#loginPopup__main-form').addClass(typeClass);
        $('.loginPopup__main-form-header').show()
        $('#reset-message').remove();
        $('#login-modal .modal-title').text('Login to your Account');
        $('#login-modal .modal-body form.login-modal').fadeIn();
        $('#login-modal .modal-body .default-view').show();
        $('#login-modal .modal-body .social_signin').show();
        $('#login-modal .modal-body .reset').hide();
        $('#login-modal .modal-footer .do-reset-btn').hide();
        $('#login-modal .modal-footer .do-login-btn').show();
        $('#login-modal .modal-footer .do-recovery-btn').hide();
        $('#login-modal .modal-footer .do-return-login-btn').hide();
        $('#login-modal .do-register-btn').hide();
        $('#login-modal .modal-footer .do-phone-btn').hide();
    });
}

function updatePhoneFormShow() {

    //load fields that we already have
    $('#login-modal .modal-body form.login-modal').fadeOut('fast', function () {
        $('#loginPopup__main-form').removeClass('login');
        $('.loginPopup__main-form-header').hide()
        $('#login-modal .modal-title').text('FINALIZE REGISTRATION');
        $('#login-modal #login-popup-form').remove();
        $('#login-modal .modal-footer .do-reset-btn').hide();
        $('#login-modal .modal-footer .do-login-btn').hide();
        $('#login-modal .modal-footer .do-recovery-btn').hide();
        $('#login-modal .modal-footer .do-return-login-btn').hide();
        $('#login-modal .do-register-btn').hide();
        $('#login-modal .modal-body form.phone').fadeIn();
        $('#login-modal .modal-footer .do-phone-btn').show();

        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.toString() !== '') {
            $('[name="first_name"]').val(urlParams.get('first_name'));
            $('[name="last_name"]').val(urlParams.get('last_name'));
            $('[name="phone"]').val(urlParams.get('phone'));
            $('[name="company"]').val(urlParams.get('company'));
            $('[name="industry"]').val(urlParams.get('industry'));
            $('[name="title"]').val(urlParams.get('title'));
            $('[name="email"]').val(urlParams.get('email'));
            $('[name="zip"]').val(urlParams.get('zip'));
        }
    });
}

//This is used in the validate email function but needed to be defined outside of it
let timeout

function validateEmail(input, error, btn) {

    clearTimeout(timeout)

    const emailRegex = /\b[\w.!#$%&’*+/=?^`{|}~-]+@[\w-]+(?:\.[\w-]+)*\b/
    const valid = emailRegex.test(input)

    if (!valid && input.length) {
        timeout = setTimeout(() => {
            error.addClass('is-error')
        }, 500)
        btn.addClass('disabled')
    } else {
        error.removeClass('is-error')
        btn.removeClass('disabled')

    }

    if (!input.length) {
        timeout = setTimeout(() => {
        }, 500)
        btn.addClass('disabled')
    }

}

function closeForm(){
    $('#loginPopup__main-form').removeClass('login');
    $('#loginPopup__main-form').removeClass('register');
    $('#loginPopup__main-form').removeClass('register-full');
    showLoginForm()
}
